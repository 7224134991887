<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <download-excel
              class="btn btn-download-excel"
              :data="excelData"
              :fields="excelFields"
              worksheet="Subscritos"
              :name="'subscribers-'+(new Date().getTime())+'.xls'"
            >
              <img src="icon-excel.svg"> Export Excel
            </download-excel>
            Subscritos
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fieldsArray"
              :items-per-page="perPage"
              :pagination="$options.paginationProps"
              index-column
              clickable-rows
            >
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Clients from "@/services/ClientsDataService";
// import usersData from './UsersData'
export default {
  name: 'Users',
  data: () => {
    return {
      items: [],
      fieldsArray: [
        { key: 'email', label: 'E-mail' },
        { key: 'updatedAt', label: 'Data' },
      ],
      perPage: 6,
      largeModal: false,
      newUsername: '',
      newEmail: '',
      newPassword: '',
      newRole: '',
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    excelFields(){
      let fields = {
        "E-mail": "email"
      }
      return fields
    },
    excelData(){
      return this.items.map(d =>{ return { "email": d.email } })
    },
  },
  methods: {
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    Clients.getSubscribers()
      .then(response => {
        this.items = response.data;
        this.items = this.items.map(s => { return {email: s.email, updatedAt: new Date(s.updatedAt).toLocaleDateString('pt-PT')} });
        console.log(this.items)
      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>

<style scoped>
  .btn-download-excel{
    float: right;
    border: 1px solid #EEE;
    background: #EEE;
    font-size: 11px;
    font-weight: bold;
    transition: 0.2s;
  }

  .btn-download-excel:hover{
    background: #EFE;
    border: 1px solid #ADA;
    color: #0c7238;
    transition: 0.2s;
  }

  .btn-download-excel img{
    width: 24px;
    margin-right: 10px;
    height: auto;
  }
</style>